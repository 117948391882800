<template>
    <base-section id="article" space="72">
        <v-container class="py-0">
            <v-row justify="center">
                <v-col cols="12" md="7">
                    <news-card class="mb-4" prominent v-bind="article" />

                    <news-share />

                    <news-author v-bind="author" />
                </v-col>

                <v-col class="hidden-sm-and-down" cols="3">
                    <news-recent-articles />
                </v-col>
            </v-row>
        </v-container>
    </base-section>
</template>

<script>
    export default {
        name: "SectionNews",

        created: function () {
            if (!this.articles[this.$route.params.slug]) {
                this.$router.push({ name: "FourOhFour" });
            } else {
                this.article = this.articles[this.$route.params.slug];
            }
        },

        components: {
            NewsRecentArticles: () => import("@/components/news/RecentArticles"),
            NewsAuthor: () => import("@/components/news/Author"),
            NewsCard: () => import("@/components/news/Card"),
            NewsShare: () => import("@/components/news/Share"),
        },

        data: () => ({
            author: {
                name: "Alton Carter",
                blurb: "",
                src: "https://accakids.org/wp-content/uploads/2015/03/Alton-Carter-full.jpg",
            },
            articles: {
                "the-foster-care-system-is-horrible": {
                    icon: "mdi-text",
                    category: "Experiences",
                    title: "The Foster Care System Is Horrible",
                    text: "",
                    src: "https://images.pexels.com/photos/1529360/pexels-photo-1529360.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
                    html: `
                      <p>Being in foster care is extremely terrible, and I can’t find a way to stress it enough in a few words to those who are reading this.</p>

                      <p>I thought being in foster care would mean having a better family than my abusive one, but I was horribly wrong… I was completely wrong. At least, I was separated from the abuse of my family, but being in foster care introduced new problems.</p>

                      <p>The worst part of going through the foster care system is that having various foster parents constantly insults my biological family. When it happens, I always feel like I was stabbed. For a period of time, my address was always changing which forced me to constantly move. It can take many years for me to become comfortable and acquainted with a new home, but every time I do, I always end up moving again the next year. I’m not saying that I’m still on the move because I’m now in a stable foster care family. I just want to express to you how detrimental it was to go through the foster care system.</p>

                      <p>Even though I am in a more stable foster care family, I still fought with one more problem: a relationship with my foster care family. I struggled a lot during my time with Marcy and Phil as their foster child. I wanted to feel like I belonged to someone or part of a family because I always hated being the outlier in a family. I also despised being called a foster child because, to me, it meant that I was temporary. I just wanted to belong to someone.</p>

                      <p>I hope anyone reading this can understand the problems I am facing in the foster care system. I want to write this to show to others how flawed foster care is.</p>
                  `,
                },
                
                "im-going-to-college": {
                    icon: "mdi-text",
                    category: "Experiences",
                    title: "I'm Finally Going to College!",
                    text: "",
                    src: "https://images.pexels.com/photos/256490/pexels-photo-256490.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
                    html: `
                        <p>I reached an important milestone in my life and in my family, but all I feel is anxiety.</p>

                        <p>For many people, entering college is exciting and fun, but I can’t really share the same excitement as them. For those who don’t know about me, I’m a foster care child, and I grew up with little familial support. Now that I’m entering college, I’m on my own path, but I barely have any money or people to ask for support. These problems may seem trivial to you if you are in a supportive family, but if I drop out, get sick or lost on campus, or lose all my money, I can’t simply return home or restart. I would be done for at that point. On top of all of that, everyone in my family seems to be counting on me to become the first to graduate from college, and I can’t imagine what it would feel like to let them all down. </p>

                        <p>In a couple of weeks from now, I’m expected to arrive on the college campus, and I’m beginning to feel more restless and overwhelmed even though I’m not even there yet. </p>

                        <p>Even with all this burden, I still believe on the off chance that I can graduate. For many years of my childhood, I remained mostly independent, and if I can make it through my childhood, I can also make it through college. I’ve heard many stories of people who grew up in poverty that become successful. If they can also do it with barely anything, I am not any different. With this in mind, I will not let my family down.</p>
                  `,
                },
                
                "dropped-out-of-college": {
                    icon: "mdi-text",
                    category: "Experiences",
                    title: "I Dropped Out of College...",
                    text: "",
                    src: "https://images.unsplash.com/photo-1589668798191-efb95b5cbd93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=701&q=80",
                    html: `
                        <p>I apologize for not posting the long-awaited college update. Everything went downhill after my last post, and I’ve been struggling to find the time and resources to post again.</p>

                        <p>Since my last update, college didn’t go well, and I dropped out. I wish there was another way to say it, but I couldn’t think of another way. It was difficult to focus on schoolwork, take notes in each class, and ask for help. When I studied for an exam, I would spend hours and hours reviewing my notes and materials. Then, the exam rolls around, and I take it. At first, I would feel confident about how I was doing, but as time passed, everyone would slowly start finishing up and leaving except for me. I ignored it and finished the exam. I thought I would’ve at least gotten a B, but in a few days, the exam came back with a D. At that point, I wanted to call for help, but I was afraid of asking too many questions. I wish I asked for help during that time because I really needed it. Nevertheless, my fears took over me. Eventually, all of my fears and problems drove me to dropping out.</p>

                        <p>It doesn’t end there. I thought I would be able to cut my losses if I returned home to my family, but I was wrong again. I tried convincing my grandfather about letting me stay, but he refused. Then, I tried staying at my mother’s house but couldn’t because it was infested with roaches and cluttered everywhere.</p>

                        <p>Since the time I came back, I had been pretty much homeless. I had lost almost everything. With nothing left, I was reduced to search for one’s bare minimum needs: food, water, and shelter.</p>

                        <p>I don’t think my life can get any lower than this so now the only way I can go is up. I still want to believe that I have the potential to become successful even though I dropped out of college. I still have a couple of my childhood friends, a set of clothes, and places I can seek for food or shelter. All I need to get back on my feet is a job. If I can apply to one of the fast-food restaurants when they start hiring again, I can focus on self-improvement without spending most of my time trying to survive. I hope the best for myself for the next couple of months as this might be the last chance I have to sustain myself.</p>
                  `,
                },
                
                "i-got-a-job-and-a-relationship": {
                    icon: "mdi-text",
                    category: "Experiences",
                    title: "I Got a Job and a Relationship!",
                    text: "",
                    src: "https://images.unsplash.com/photo-1558624153-392091714599?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80",
                    html: `
                        <p>I can’t believe the amount of progress I have made. I struggled to find food several months ago, and now I have a job and a relationship.</p>

                        <p>Getting a job at a fast-food restaurant is about as easy as it seems: you fill out a short application. After that, I was hired at Long John Silver’s, but I didn’t last very long as the manager fired me over a petty argument. When I look back at that restaurant, I still get irritated by the thought of that manager. Since my employment at Long John Silver’s ended, I was later hired at my local McDonald’s to open the restaurant at 5:00 A.M. every morning. I was also given a $4.25 per hour minimum wage and a free meal per shift, which was extremely helpful.</p>

                        <p>However, there was one thing that made me feel sick inside: the amount of wasted food. I grew up from my childhood with very little food, and the strict rules at McDonald’s forced us to throw away food that could feed hundreds of families. Instead, I took the matters into my own hands and brought the food with me that would be thrown out because it would’ve all ended up in the dumpster anyway.</p>

                        <p>I don’t want to keep complaining about my job because I want to focus on improving myself upwards in life.</p>

                        <p>While I got a job, I also met someone named Kristin at the OSU Colvin Center. When we met, we immediately became inseparable and always wanted to spend time with each other. Well, maybe I lied a little, but we’re still in a healthy relationship. The first time we dated, I didn’t show up at all because I didn’t have enough money to buy tickets or go to the movies. I was also afraid of her discovering my situation if I allowed her to pick me up or pay for my tickets. Since I didn’t tell her I wouldn’t show up, I profusely apologized to Kristin a few days later until she could agree to go again. After she agreed on a second chance, I made sure to do it right. Looking back, our second date went tremendously well because after we finished watching the movie, we laughed and talked for hours, to the point that I almost forgot about my poverty and problems and simply just enjoyed the presence of having her by my side.</p>

                        <p>Over time, we became more close and discovered we wanted to spend the rest of our lives together. At this point, I had learned enough from movies and stories and bought the best ring I could at the jewelry store. When we were together at home, I proposed to her and she said yes! We plan to marry in August of 1995.</p>
                    `,
                },
                
                "were-married": {
                    icon: "mdi-text",
                    category: "Experiences",
                    title: "We're Married!",
                    text: "",
                    src: "https://images.unsplash.com/photo-1548889918-9b24c0bf5333?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=633&q=80",
                    html: `
                        <p>We officially got married on August 12, 1995!</p>

                        <p>Our wedding went beautifully, and we also had many people over to attend it, such as my grandpa and several amazing groomsmen: Michael Davis, Mitch Carson, and Blaine Douglas.</p>

                        <p>On Kristin’s side, she also had several people attend it, such as her sister, high school friends, and family.</p>

                        <p>After we married, we went on a honeymoon in San Antonio, Texas, but ended it short to return to Stillwater. On the bright side, we used the honeymoon money to furnish our house.</p>

                        <p>Along with our marriage, I brought along an odd habit from my childhood. At random times throughout the day, I would open the refrigerator just to look inside it. From my childhood, I would rarely have any food. If there was any, I would always be captivated by the idea of it. Since I grew up from then, I would always check the refrigerator throughout the day to make sure there was still food. I tried to stop myself from checking the fridge several times but found it hard to shake off the habit.</p>

                        <p>Besides my bad habit of checking the fridge, Kristin is very understanding of my situation. She would go out of her way to help whenever possible. For example, the honeymoon was one of them because she decided it would be best to reallocate the money on necessities and provide to others in need. For this, I want to thank her for her support through the lowest points of my life and the many hurdles I have faced. I hope that I can repay her through our marriage.</p>
                    `,
                },
                
                "i-am-becoming-a-father": {
                    icon: "mdi-text",
                    category: "Experiences",
                    title: "I Am Becoming a Father!!!",
                    text: "",
                    src: "https://images.unsplash.com/photo-1527620743113-fac8dd0f8d17?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80",
                    html: `
                        <p>It’s official. I am becoming a father, and I’m proud of it.</p>

                        <p>In preparation for the arrival of our baby boy, I am doing a lot to help my wife, Kristin, throughout her pregnancy. I am preparing meals, attending appointments with Kristin, and comforting her as pregnancy is putting her through a lot of sick days and trouble. Outside of helping Kristin, I have been doing a massive amount of research. I have read virtually every book available about raising a baby and asked every possible question to anyone near me. I want to be the best dad I can to my children and provide them a wonderful childhood.</p>

                        <p>Aside from my excitement of having a son of my own, I’m worried about repeating the same mistakes my parents had made during my childhood and being shunned by my children later on. It will cause me a lot of pain if I fail to provide enough love and attention to my children. This is not something I can simply resolve by persisting through it, like my homelessness. If I fail as a father, it would be deeply ingrained in my children.</p>

                        <p>All of this is overwhelming. Now that I’m reflecting on all of my fears, it’s starting to look like I’m experiencing my college years again. However, I now have the support of my wife and my local community around me, and I hope to use all my efforts to prove myself to be a good father.</p>
                    `,
                },
                
                "welcome-kelton-to-the-world": {
                    icon: "mdi-text",
                    category: "Experiences",
                    title: "Welcome Kelton to the World",
                    text: "",
                    src: "https://images.unsplash.com/photo-1470116945706-e6bf5d5a53ca?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NHx8cHJlZ25hbmN5fGVufDB8fDB8&auto=format&fit=crop&w=500&q=60",
                    html: `
                        <p>I would like to introduce to everyone Kelton, our first child. He is everything I could have ever wanted as a father. He weighs eight pounds and has the blondest hair I have ever seen in my life. We named him Kelton Thomas with the first name combining our two names and the last name my grandpa’s name.</p>

                        <p>Leading up to Kelton’s birth, I have saved all the ultrasounds on laminated paper and attended every prenatal appointment with Kristin. Along with that, I dedicated a room in our house to become a nursery and decorated it with many sailboats. I really want to make sure I can provide everything I can to Kelton.</p>

                        <p>I have also tried making a toy box, but it went horribly wrong as I started building it without any measurements. I ended up scrapping it and redoing it again after the toy box was way larger than I was comfortable with.</p>

                        <p>Now that Kelton is born, I feel very overjoyed. Kelton is all I needed to help me live through the nights of restlessness and give me the courage to become a wonderful dad. Without Kelton, I wouldn’t have ever been able to have the same amount of courage to tackle my problems without him. I also hope that he can someday share the same feelings I do and be proud of me.</p>
                    `,
                },
                
                "welcome-colin-our-second-son": {
                    icon: "mdi-text",
                    category: "Experiences",
                    title: "Welcome Colin, Our Second Son",
                    text: "",
                    src: "https://images.unsplash.com/photo-1511948374796-056e8f289f34?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80",
                    html: `
                        <p>Colin is one of the most beautiful baby boys I have ever seen. With thick dark hair and brown skin, I am incredibly proud of him.</p>

                        <p>After Colin was born, all Kelton wanted to do was carry Colin in his arms for hours at a time and cuddle with him.</p>

                        <p>Throughout my life, Colin and Kelton were all I wanted as a father. They were the missing parts that I needed in my life. Though they are both still young, I can imagine what it will be like when they grow up. I imagine playing sports with them, helping them throughout their school year, and a lot more. I want to make it my duty to be a father they can count on as they grow up.</p>

                        <p>With all of this, I still face awkward situations from our skin colors. When we went to Walmart, a woman kept staring at us oddly until we approached her. Without any hesitation, she asked whose father does the boys belong to as if it was a completely normal question. I wanted to blow up when she said that question because it’s incredibly rude and absurd to question someone by their skin color. Luckily, I didn’t get any time to confront her because it would’ve caused a lot of headaches. After she asked the question, Kristin quickly pointed at me and moved on without anymore confrontation. Although I wanted to find the lady, Kristin’s action was right, and it saved me a lot of trouble.</p>

                        <p>Even with this strange encounter, I am still confident in our own family. A couple of people’s opinions in public will not discourage me from being the best father and husband in my family. Instead of letting the criticism of others affect me, I want to focus more on my family and find ways we can improve each other together.</p>
                    `,
                },

            },
            article: {},
        }),
    };
</script>
