










import { Component, Vue } from 'vue-property-decorator';

import Article from '@/components/sections/Article.vue';
import SocialMedia from '@/components/sections/SocialMedia.vue';
import News from '@/components/sections/News.vue';

@Component({
  components: {
    Article,
    SocialMedia,
    News
  },
  metaInfo: () => ({
    title: 'Blog Entry'
  })
})
export default class Blogs extends Vue {
  
}
